<script setup>
import { useUserStore } from '@/stores/User';

const props = defineProps({
  additionalClassNames: {
    type: String,
    default: '',
  },
});

defineEmits(['close-dialog']);

const userStore = useUserStore();
const canAddRelatedFavorites = computed(() => userStore.addRelatedFavorites);

const paragraphClass = computed(() => [
  'tw-text-sm text-light-color',
  props.additionalClassNames,
]);
</script>

<template>
  <p
    v-if="canAddRelatedFavorites"
    :class="paragraphClass"
  >
    We will also add snow alerts and the most relevant webcams, local experts, and trail maps to
    your favorites list. You can always change this in your
    <NuxtLink
      class="tw-underline link-color-brand"
      to="/user/settings/favorites#preferences"
      @click="$emit('close-dialog')"
    >Settings</NuxtLink>.
  </p>
  <p
    v-else
    :class="paragraphClass"
  >
    Want to save time? Turn on Quick Add in <NuxtLink
      class="tw-underline link-color-brand"
      to="/user/settings/favorites#preferences"
      @click="$emit('close-dialog')"
    >Settings</NuxtLink> to get snow alerts, web cams, local experts, and trail maps added
    automatically when favoriting locations
  </p>
</template>
